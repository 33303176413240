<template>
  <LayoutParametrage title="Partenaires">
    <template #current_page>
      <span v-if="$route.name != 'update-activity-sector'">
        Ajouter un secteur d'activité
      </span>
      <span v-else>Modifier le secteur d'activité</span>
    </template>
    <template #add_button>
      <router-link
        class="text-promy-green-300 cursor-pointer"
        :to="{
          name: 'list-activity-sector',
        }"
      >
        &larr; retour
      </router-link>
    </template>
    <template #modal>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form_ref">
        <div class="form__info_fields_layout">
          <div class="flex flex-col space-y-5">
            <pro-input
              name="nom"
              requirements="required"
              v-model="form.nom"
              label="Nom Secteur d'activités"
            />
            <div class="relative md:w-full h-64 mt-4">
              <label class="form__info_field_label"
                >Description secteur d'activité:</label
              >
              <quill-editor
                class="mt-2 h-full"
                ref="myQuillEditor"
                v-model="form.description"
              />
            </div>
            <div class="sm:mt-6 sm:w-full pt-20 max-w-sm">
              <label class="form__info_field_label"
                >Image secteur d'activité :</label
              >
              <InputImage
                :isFormData="true"
                :file="form.image"
                :selfUploader="true"
                @upload="uploadedImage"
                @deleteImage="() => (form.image = null)"
              />
            </div>
            <select
              v-model="form.categorie_secteur_activite_id"
              class="rounded-full px-4 py-2 text-gray-600 border-solid border w-full mt-1 text-sm bg-promy-gray-background-300 appearance-none"
              name="Category"
            >
              <option
                :value="category.id"
                v-for="(category, idx) in categories"
                :key="category + idx"
              >
                {{ category.nom }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full my-8" v-if="$can('edite,partenaire')">
          <button
            v-if="$route.name === 'update-activity-sector'"
            class="form__update_cta btn-green"
            @click="update(handleSubmit)"
          >
            Modifier
          </button>
          <button
            v-else
            class="form__add_cta btn-green"
            @click="add(handleSubmit)"
          >
            Ajouter
          </button>
        </div>
      </ValidationObserver>
    </template>
  </LayoutParametrage>
</template>

<script>
export default {
  data() {
    return {
      form: {
        nom: '',
        description: '',
        image: null,
        categorie_secteur_activite_id: null,
      },
      categories: [],
    }
  },
  created() {
    if (this.$route.name === 'update-activity-sector') {
      this.$http
        .get(`admin/secteurs-activite/${this.$route.params.id}`)
        .then((res) => {
          this.form = res.data
          console.log(this.form)
        })
    }
    this.fetchCategories()
  },
  methods: {
    uploadedImage(file) {
      this.form.image = file
    },
    fetchCategories() {
      this.$http.get('admin/categories-secteurs-activite').then((res) => {
        this.categories = res.data
      })
    },
    update() {
      const formData = new FormData()
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value)
      }

      this.$http
        .post(
          `admin/secteurs-activite/${this.$route.params.id}?_method=PUT`,
          formData,
          {
            'Content-Type': 'multipart/form-data',
          },
        )
        .then((res) => {
          this.$router.push({
            name: 'list-activity-sector',
          })
        })
    },
    add() {
      const formData = new FormData()
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value)
      }
      this.$http
        .post('admin/secteurs-activite', formData, {
          'Content-Type': 'multipart/form-data',
        })
        .then((res) => {
          this.$router.push({
            name: 'list-activity-sector',
          })
        })
    },
  },
}
</script>

<style>
.form__types_field_layout {
  @apply mb-12 flex md:grid md:grid-cols-2 sm:grid-cols-1 md:gap-x-4 md:gap-y-8 space-x-12 md:space-x-0;
}

.form__types_field_group {
  @apply py-8 pl-7 pr-5 bg-[#F0F0F0] rounded-xl;
}

.form__types_field_items {
  @apply h-56 pr-16 space-y-4 overflow-y-auto;
  scroll-behavior: smooth;
}

.form__info_fields_layout {
  @apply flex space-x-24 lg:space-x-8 md:space-x-0 md:flex-col md:space-y-8;
}

.form__info_fields_group {
  @apply grid grid-cols-2 gap-x-4 sm:grid-cols-1 sm:gap-x-0 sm:gap-y-4;
}

.form__info_field_label {
  @apply capitalize text-xs text-gray-600 font-bold font-arial;
}

.form__slugs_field_container {
  @apply relative w-2/3 sm:w-full lg:mt-30 mt-28 sm:mt-34;
}

.form__update_cta {
  @apply px-6 py-2 sm:w-full mr-4;
}

.form__delete_cta {
  @apply px-6 py-2 sm:w-full sm:mt-4 hover:bg-promy-red hover:border-promy-red;
}

.form__add_cta {
  @apply px-6 py-2 sm:w-full md:mt-8;
}

.form__remove_confirm_message {
  @apply text-promy-gray-700 text-sm font-arial py-4;
}

.form__remove_confirm_canceler {
  @apply px-6 py-2 sm:w-full md:mt-8 mr-4;
}

.form__remove_confirm_approver {
  @apply px-6 py-2 sm:w-full md:mt-8 hover:bg-promy-red hover:border-promy-red;
}
</style>
